<template>
 <div :class="windowClass">
   <v-container>
    <v-row>
      <v-col cols="12">
                <v-card>
               <v-toolbar>
                 <v-icon left color="secondary">mdi-cogs</v-icon> Settings
                 <v-spacer/>
                 
                 <v-divider vertical inset class="ml-5 mr-2"/>
                        <v-btn text small color="success"  @click="state='new',form=true"><v-icon small left color="success">mdi-plus</v-icon>  Add Game</v-btn>
               </v-toolbar>
               <v-card-text>
                <v-data-table
                    class="va-table"
                    dense
                    :headers="headers"
                    :items="games"
                    >
                    <template v-slot:body="{ items }">
                        <tbody>
                        <tr v-for="(item,index) in items" :key="index">
                            <td>
                            <v-icon  x-small left color="warning" @click="game=item, state='update', form=true" >mdi-pencil</v-icon>
                            {{ item.name }}</td>
                            <td>{{ item.code }}</td>
                            <td>{{ item.type }}</td>
                            <td>{{ $money(item.winning_amount) }}</td>
                            <td>{{item.payout_percent}}%</td>
                            <td>{{ $money(item.fund) }}</td>
                            <td>
                              <v-chip small class="def-font ma-1" v-for="(item,index) in item.Draws" :key="index + '-d'" color="info"> {{item}}</v-chip>
                            </td>
                             <td>
                               <v-chip small :color="item.status=='Active'?'success':''"> {{ item.status }}</v-chip>
                              </td>
                        </tr>
                        </tbody>
                    </template>
                    </v-data-table>
               </v-card-text>
             </v-card>
          </v-col>
    </v-row>
    </v-container>
    <va-game-form :show="form" :game="game" :state="state" @DialogEvent="afEvent" />
  </div>
</template>
<script>
import {
  mapMutations
} from 'vuex'
  export default {
    data: () => ({
        form: false,
        areaform: false,
        state: 'new',
        game: {},
        games:[],
        headers: [
            { text: 'Game', value:"username"},
            { text: 'Code'},
             { text: 'type'},
            { text: 'Win Amount'},
            { text: 'Payout(%)'},
            { text: 'Funding'},
             { text: 'Draw Times'},
             { text: 'Status'}
        ],
    }),
    beforeCreate: function () {
      if (!this.$session.exists()) {
        this.$router.push('/')
      }
    },
    created() {
       if (this.$session.exists()) {
          this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + this.$session.get('jwt')
          this.setLoggedIn(true) 
         this.getGames()
         //s this.getAreas()
        }
    },
    computed:{
       windowClass() {
        if(this.$store.state.responsive){
          return "va-reponsive"
        } else {
          return "va-not-reponsive"
        }
      },
    },
    methods: {
      ...mapMutations(['setAlert', 'setAlertColor', 'setAlertText', 'setLoggedIn']),
      afEvent() {
        this.form = false
        this.getGames()
      },
      formEvent(data){
          this.form = false
          if(data.action != 'close') {
              this.user = data.user
              this.Save()
          }
          console.log(data)
      },

      getGames(){
           this.$http.get("game/list",).then(response => {
            response.data.games != null?this.games = response.data.games:this.games =[]
            console.log(this.games)
          }).catch(e => {
            console.log(e.data)
          })
      },
      
     
    }
  }
</script>